import axios from "axios"

console.log(process.env)

export default (extraConfig = {}) => {
  return axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    ...extraConfig
  })
}

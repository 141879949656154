// Most of the code here are from NorTV On Demand

import router from "@/router";
import getHttp from "@/AxiosBase";

const HTTP = getHttp();
const state = {
    user: {},
};
const mutations = {
    setUser(state, data) {
        this.dispatch("category/getAll");
        state.user = data;
    },
};
const actions = {
    getUser({ commit }) {
        let vm = this._vm;

        HTTP.get("/user/@me", {
            headers: {
                "X-API-Key": localStorage.getItem("access_token"),
            },
        })
            .then((response) => {
                commit("setUser", response.data);
            })
            .catch((e) => {
                if (e.response && e.response.status === 401) {
                    return // Invalid Token, don't send error notification
                }
                vm.$notify({
                    title: "Error",
                    text: e,
                    type: "error"
                });
            });
    },
    refreshCurrentToken() {
        // let vm = this._vm;
        let store = this;

        let refresh_token = localStorage.getItem("refresh_token");

        HTTP.post(`/auth/refresh?refresh_code=${refresh_token || ""}`)
            .then((response) => {
                if (response.data.access_token) {
                    localStorage.setItem(
                        "access_token",
                        response.data.access_token
                    );

                    localStorage.setItem(
                        "refresh_token",
                        response.data.refresh_token
                    );

                    localStorage.setItem(
                        "expire_date",
                        response.data.expire_date
                    );
                    store.dispatch("currentUser/getUser");
                } // else {
                // 	console.log(vm);
                // 	vm.$vs.notification({
                // 		title: "Error",
                // 		text: response.detail || "Something went wrong!",
                // 	});
                // 	router.push({ name: "Home" });
                // }
            })
            .catch(() => {});
    },
    // eslint-disable-next-line no-empty-pattern
    loginWithDiscord({ }, discord_code) {
        let vm = this._vm;
        let store = this;

        HTTP.post(
            `auth/discord/login?code=${discord_code}`
        )
            .then((response) => {
                if (response.data.access_token) {
                    localStorage.setItem(
                        "access_token",
                        response.data.access_token
                    );

                    localStorage.setItem(
                        "refresh_token",
                        response.data.refresh_token
                    );

                    localStorage.setItem(
                        "expire_date",
                        response.data.expire_date
                    );
                    store.dispatch("currentUser/getUser");
                    router.push({ name: "VotingPage" });
                } else {
                    console.log(vm);
                    vm.$notify({
                        title: "Error",
                        text: response.detail || "Something went wrong!",
                        type: "error"
                    });
                    router.push({ name: "Home" });
                }
            })
            .catch((e) => {
                vm.$notify({
                    title: "Error",
                    text: e,
                    type: "error"
                });
                router.push({ name: "Home" });
            });
    },
};
const getters = {
    user: (state) => state.user,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

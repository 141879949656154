<template>
	<div class="main_container">
		<div class="delta-wing-container">
			<img src="~@/assets/DeltaWing.svg" alt="DeltaWing" class="delta-wing" />
		</div>
		<div class="title-contanier">
			<p class="title text-h1 mb-2">AviAwards</p>
			<p class="description text-h6 text-uppercase text-center">
				<b>The voting has closed, thank you for participating in AviAwards 2021</b>
			</p>
			<ProducedByNorTV />
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import ProducedByNorTV from "@/components/ProducedByNorTV.vue";

export default {
	name: "votingEnded",
	components: {
		ProducedByNorTV,
	}
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/color";

.main_container {
	height: 100vh;
	width: 100vw;
	display: flex;
}

.delta-wing-container {
	position: absolute;
	display: flex;
	height: 100%;
	width: 100%;

	justify-content: flex-end;
	flex-direction: column;
	align-items: flex-end;
	align-content: center;

	.delta-wing {
		height: 80%;
		z-index: 0;
		user-select: none;
		-webkit-user-select: none;
		-webkit-user-drag: none;
		-webkit-app-region: no-drag;
		cursor: default;
	}
}

.title-contanier {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-content: center;
	align-items: center;
	margin-left: 5%;

	* {
		z-index: 5;
	}

	p {
		font-weight: 500;
		color: $gray;
	}

	.title {
		font-size: 6em;
		text-transform: uppercase;
		line-height: 70%;
	}

	.description {
		font-size: 3.1em;
		font-weight: 100 !important;

		b {
			font-weight: 500;
		}
	}

	@media only screen and (max-width: 768px) {
		.title {
			font-size: 3.3em !important;
		}
		.description {
			font-size: 1.2em !important;
		}

		margin-left: 0%;
		width: 100%;
	}
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Callback from "../views/Callback.vue";
import VotingPage from "../views/VotingPage.vue";
import VoteingEnded from "../views/VotingEnded.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/callback/discord",
    name: "DiscordCallback",
    component: Callback,
  },
  {
    path: "/voting-page",
    name: "VotingPage",
    component: VotingPage
  },
  {
    path: "/voting-ended",
    name: "VotingEnded",
    component: VoteingEnded
  }
]

const router = new VueRouter({
  routes,
  mode: "history"
})

export default router

<!--
item.state:
  null: did not vote in this category
  false: did not vote for item
  true: voted for this item
-->
<template>
	<div class="vote-item">
		<img class="item-preview" :src="image" />
		<div class="item-info">
			<p class="name">{{ name }}</p>
			<p class="description">{{ description }}</p>
			<v-btn
				color="primary"
				class="vote-btn"
				v-on:click="$emit('voteClicked', id)"
				:disabled="state === false"
				>{{ state == false ? "voted" : "vote now" }}
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	name: "voteItem",
	props: ["name", "description", "image", "state", "id"],
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/color";

.vote-item {
	display: flex;
	gap: 2%;
	width: min-content;

	.item-preview {
		width: 230px;
		height: 230px;
		background: linear-gradient(
			180deg,
			rgba(188, 170, 129, 1) 50%,
			rgba(188, 170, 129, 0.2) 100%
		);
	}

	.vote-btn {
		background: linear-gradient(
			180deg,
			rgba(177, 161, 122, 1) 55%,
			rgb(71, 66, 54) 100%
		) !important;

		margin: 2% 2%;
		margin-top: auto;

		width: 120px;
		color: black;
	}

	.item-info {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 50%;
		.name,
		.description {
			text-transform: uppercase;
		}

		.description {
			font-size: 0.9em;
			width: 50px;
			border-top-width: 1px;
			border-color: gray;
			border-style: solid;
			padding-top: 10%;
		}
	}

	p {
		color: white;
	}
}

@media only screen and (max-width: 930px) {
	.vote-item {
		.item-preview {
			height: 210px;
			width: 210px;
		}
	}
}

@media only screen and (max-width: 600px) {
	.vote-item {
		.item-preview {
			height: 170px;
			width: 170px;
		}

		.item-info {
			width: 30%;

			.description {
				font-size: 0.7em;
			}
		}

		margin-bottom: 3%;
	}
}

@media only screen and (max-width: 320px) {
	.vote-item {
		.item-preview {
			height: 140px;
			width: 140px;
		}

		.item-info {
			width: 20%;
		}

		margin-bottom: 3%;
	}
}
</style>

<template>
	<div class="fullscreen-loader">
		<v-progress-circular
			indeterminate
			color="white"
			:size="70"
			:width="7"
		></v-progress-circular>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.fullscreen-loader {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 1500;

	background-color: rgba(0, 0, 0, 0.25);
}
</style>
<template>
	<v-app id="app">
		<router-view class="router-view" />
		<notifications />
	</v-app>
</template>

<style lang="scss">
@import "~@/assets/css/color";
@import "~@/assets/css/css_reset";

#app,
body {
	background-color: $background;
	overflow: hidden !important;
}

* {
	font-family: "Work Sans", sans-serif !important;
}

.custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 5px;
  background-color: #F5F5F5;
}

.custom-scroll::-webkit-scrollbar {
  width: 12px;
  height: 7px;
  background-color: #F5F5F5;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}

// .router-view {
// position: absolute;
// }
</style>

<script>
export default {
	created() {
		this.$store.dispatch("currentUser/refreshCurrentToken");
		this.$store.dispatch("currentUser/getUser");
	},
};
</script>

<template>
	<div class="voting-page">
		<loadFullscreen v-if="!currentData | voteProcessing" />
		<div class="category-title" v-if="!(currentData && currentData.length < 1)">
			<div class="gradient-bar">
				<button v-on:click="nextItem">
					<img
						src="~@/assets/Arrow.svg"
						id="top-arrow"
						class="arrow-btn"
						height="30px"
					/>
				</button>
				<p v-if="currentData[currentItem]">
					Category: {{ currentData[currentItem].name }}
				</p>
				<button v-on:click="previousItem">
					<img
						src="~@/assets/Arrow.svg"
						id="bottom-arrow"
						class="arrow-btn"
						height="30px"
					/>
				</button>
			</div>
		</div>
		<div class="category-items" v-if="currentData && currentData.length > 0">
			<div
				class="category-container custom-scroll"
				v-for="category in currentData"
				v-show="!category.voting_closed"
				:key="category.id"
				:ref="category.id"
			>
				<voteItem
					v-for="item in category.items"
					v-on:voteClicked="voteItem(item)"
					:key="item.id"
					:name="item.name"
					:description="item.description"
					:image="item.image"
					:state="item.state"
					:id="item.id"
				/>
			</div>
		</div>
		<div class="category-items" v-if="!currentData">
			<div class="category-container custom-scroll pa-3">
				<v-skeleton-loader
					class="skeleton"
					type="image"
					v-for="i in 4"
					:key="i"
				></v-skeleton-loader>
			</div>
		</div>
	</div>
</template>

<script>
import voteItem from "@/components/VoteItem.vue";
import loadFullscreen from "@/components/LoadFullscreen.vue";
import getHttp from "@/AxiosBase";

const HTTP = getHttp();

export default {
	mounted() {
		let userData = this.$store.getters["currentUser/user"];

		if (!userData) {
			this.$notify({
				title: "Error",
				text: "You are not logged in.",
				type: "error",
			});
		}

		setInterval(() => {
			this.$store.dispatch("category/getAll");
		}, 7000);
	},
	beforeCreate() {
		this.$store.dispatch("category/getAll");
		this.$store.dispatch("currentUser/getUser");
	},
	data() {
		return {
			currentItem: 0,
			voteProcessing: false,
		};
	},
	methods: {
		previousItem() {
			let currentData = this.$store.getters["category/allPublic"];
			if (this.currentItem + 1 < currentData.length) {
				this.currentItem += 1;
			}
		},
		nextItem() {
			if (this.currentItem > 0) {
				this.currentItem -= 1;
			}
		},
		voteItem(itemData) {
			this.voteProcessing = true;
			HTTP.post(
				"/item/vote_item",
				{
					id: itemData.id,
				},
				{
					headers: {
						"X-API-Key": localStorage.getItem("access_token"),
					},
				}
			)
				.then(() => {
					this.voteProcessing = false;
					this.$notify({
						title: "Success",
						text: "Your vote has been submitted.",
						type: "success",
					});

					this.$store.dispatch("currentUser/getUser");
				})
				.catch((e) => {
					this.voteProcessing = false;
					this.$notify({
						title: "Error",
						text: e.response.data.error || "Your vote could not be submitted.",
						type: "error",
					});
				});
		},
	},
	watch: {
		currentItem() {
			let currentData = this.$store.getters["category/allPublic"];
			this.$refs[currentData[this.currentItem].id][0].scrollIntoView({
				behavior: "smooth",
			});
		},
	},
	computed: {
		currentData() {
			return this.$store.getters["category/allDisplayData"];
		},
	},
	components: { voteItem, loadFullscreen},
};
</script>

<style lang="scss" scoped>
.voting-page {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	align-content: center;

	gap: 2%;
	padding: 3%;

	.category-title {
		width: 5%;
		height: 100%;

		display: flex;
		align-items: center;
		justify-content: center;
		order: 1;

		.gradient-bar {
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			width: 40px;
			height: 80vh;
			max-height: 400px;

			border-radius: 3px;

			background: linear-gradient(
				180deg,
				rgba(188, 170, 129, 1) 70%,
				rgba(188, 170, 129, 0.15) 100%
			);

			p {
				white-space: nowrap;
				margin-left: 15%;
				font-weight: 400;
				font-size: 2em;
				color: white;
			}

			#top-arrow {
				transform: rotate(-90deg);
			}

			#bottom-arrow {
				transform: rotate(90deg);
			}

			button {
				z-index: 5;
			}
		}
	}

	.category-items {
		order: 2;
		width: 60%;
		height: 75vh;
		overflow: hidden;
	}

	.category-container {
		height: 100%;
		width: 95%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-around;
		gap: 10px;

		overflow-x: hidden;
		overflow-y: auto;

		.skeleton {
			width: 40%;
			margin: 0em;
		}
	}

	@media only screen and (max-width: 930px) {
		flex-direction: column;
		justify-content: flex-end;
		.category-title {
			width: 100%;
			height: 10%;
			order: 3;
			margin-top: 5%;

			.gradient-bar {
				width: 80%;
				height: 35px;
				margin: 0em;
				justify-content: center;
				flex-direction: row;
				align-items: center;
				align-content: space-between;

				p {
					font-size: 1.3em;
					padding-bottom: 75px;
					margin: 0em;
					position: absolute;
				}

				button {
					margin-left: auto;
					margin-right: auto;

					.arrow-btn {
						transform: none !important;
					}

					#top-arrow {
						transform: rotate(180deg) !important;
					}
				}
			}
		}

		.category-container {
			.skeleton {
				width: 300px;
				margin: 0em;
			}
		}

		.category-items {
			width: 95%;
		}
	}
}
</style>
<template>
	<div class="produced-by-nortv">
		<p class="text-h6 font-weight-light text-uppercase text-center">
			Produced By
		</p>
		<img
			src="~@/assets/logo.svg"
			alt="NorTV Logo"
			height="50px"
			class="grayscale-logo"
		/>
	</div>
</template>

<script>
export default {
	name: "ProducedByNorTV",
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/color";

.produced-by-nortv {
	margin: 3%;
	display: flex;
	flex-direction: column;

	p {
		color: $gray;
	}

	.grayscale-logo {
		-webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
		filter: grayscale(1);
	}
}
</style>
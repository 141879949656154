import Vue from "vue";
import Vuex from "vuex";
import currentUser from "./modules/currentUser";
import category from "./modules/category";

// import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    currentUser,
    category
  },
  // plugins: [createPersistedState()],
});

export default store;

import router from "@/router";
import getHttp from "@/AxiosBase";

const HTTP = getHttp();
const state = {
	allCategory: [],
	openCategory: [],
	displayCategory: [],
};
const mutations = {
	addCategory(state, data) {		
		const userData = this.getters["currentUser/user"];
		state.allCategory = data;
		state.openCategory = []
		
		data.forEach(function (item) {
			if (!item.voting_closed) {
				state.openCategory.push(item)
			}
		});

		let displayCategory = Object.assign([], state.openCategory);

		displayCategory.forEach((category) => {
			let existingVote = false;
			for (let i = 0; i < category.items.length; i++) {
				if (userData.voted_items.includes(category.items[i].id)) {
					existingVote = category.items[i].id;
					break;
				}
			}

			category.items.forEach((item) => {
				if (existingVote === item.id) {
					item.state = false;
				} else if (existingVote != false) {
					item.state = true;
				} else {
					item.state = null;
				}
			});
		});

		state.displayCategory = displayCategory;

		if (state.openCategory && state.openCategory.length < 1 && router.currentRoute.name == "VotingPage") {
			router.push({ name: "VotingEnded" });
		}
	},
};
const actions = {
	getAll({ commit }) {
		let vm = this._vm;
		HTTP.get(
			`catalog`,
			{
				headers: {
					"X-API-Key": localStorage.getItem("access_token"),
				},
			}
		)
			.then((response) => {
				if (response) {
					commit("addCategory", response.data);
				} else {
					console.log(vm);
					vm.$notify({
						title: "Error",
						text: response.detail || "Something went wrong!",
						type: "error",
					});
					router.push({ name: "Home" });
				}
			})
			.catch((e) => {
				vm.$notify({
					title: "Error",
					text: e,
					type: "error"
				});
			});
	}
};
const getters = {
	all: (state) => state.allCategory,
	allPublic: (state) => state.openCategory,
	allDisplayData: (state) => state.displayCategory
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};

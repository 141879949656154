<template>
	<loadFullscreen />
</template>

<script>
import loadFullscreen from "@/components/LoadFullscreen"

export default {
	mounted() {
		let discord_code = this.$route.query.code;
		if (!discord_code) {
			this.$notify({
				title: "Error",
				text: "You do not have permission to visit this page.",
				type: "error",
			});
			this.$router.push({ name: "Home" });
		} else {
			this.$store.dispatch("currentUser/loginWithDiscord", discord_code);
		}
	},
	components: {
		loadFullscreen
	},
};
</script>

<style lang="scss" scoped>
</style>